.meta_page_hidden {
  display: none;
}

.meta_page {
  display: flex;
  flex-direction: row;
  background-color: white;
  color: $cool-black;
  flex-wrap: wrap;

  .container_1 {
    width: 60%;
    min-width: 400px;

    header {
      h2 {
        color: $cool-black;
        color: $red-background;
        padding: 15px 0 8px 0px;
        margin: 0 30px 5px 0;
        font-size: 27px;
      }
    }

    ul {
      margin-right: 30px;

      li {
        list-style-type: square;
        max-width: 600px;
        border-bottom: 1px dotted $red-background;
        padding: 15px 20px 15px 0px;
        list-style: none;

        dd {
          padding: 0px 20px 10px 0px;
          font-size: 20px;
          color: $red-background;
        }
        dfn {
          padding: 0px 20px 0px 0px;
          margin-bottom: 20px;
          font-style: normal;

          p {
            padding: 10px 0 0 20px;
            margin-left: 0;
            margin-bottom: -15px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 15px;

            code {
              color: $blue;
              color: $red-background;
            }
          }
        }
      }
    }
  }

  .container_2 {
    width: 40%;
    min-width: 282px;
    height: 100%;
    aside {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding-left: 10px;

      h2 {
        color: $red-background;
        padding: 15px 0 8px 0px;
        margin: 0 30px 5px 0;
        font-size: 35px;
      }

      h2:nth-of-type(2) {
        margin-top: 40px;
        color: yellow;
      }

      img {
        width: 80%;
        height: auto;
        border-radius: 15px;
        margin-left: 26px;
        box-shadow: 20px;
      }
    }
    .meta_item_2 {
      margin-top: 20px;
    }
    .meta_item_3 {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 960px) {
    .container_1 {
      width: 100%;

      ul li {
        max-width: none;
      }
    }

    .container_2 {
      width: 100%;

      aside {
        border-left: 0;
        padding-left: 0;
        margin-top: 20px;

        .meta_item_2 {
          margin-top: 0px;
        }

        img {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          margin-bottom: 20px;
          margin-top: 20px;
          align-self: center;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .container_1 ul li:nth-last-of-type(1) {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .container_2 aside {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 670px) {
    .container_2 {
      .meta_item_3 {
        margin-top: -15px;
      }
    }
  }

  @media screen and (max-width: 430px) {
    .container_1 {
      h2 {
        font-size: 28px;
      }
    }
    .container_2 {
      aside {
        h2 {
          font-size: 28px;
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .container_2 {
      .meta_item_3 {
        margin-top: -25px;
      }
    }
  }
}
