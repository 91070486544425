.home_page_container_inactive {
  display: none;
}

.home_page_container {
  main {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    background-color: $cool-black;
    color: white;
    padding-bottom: 40px;

    h2 {
      color: $red-background;
      margin-bottom: 10px;
    }

    .flex_item_1 {
      width: 65%;

      ol {
        list-style-type: decimal;
      }
      ol:nth-of-type(1) {
        list-style-type: square;
      }

      li {
        margin-left: 20px;
      }

      .link {
        color: $red-background;
      }
    }

    .flex_item_2 {
      width: 30%;
      min-width: 282px;
      p {
        padding-bottom: 20px;
      }

      .set_up_section {
        margin-top: 10px;
        // border-top: 1px dotted $red-background ;
        li {
          margin-left: 20px;
          margin-right: 10px;
          list-style-type: square;
          padding: 20px 10px 0;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    main {
      .flex_item_1 {
        width: 100%;
        // align-self: center;
      }

      .flex_item_2 {
        width: 100%;
        aside {
          border-left: 0;
          padding-left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 430px) {
    main {
      h2 {
        font-size: 28px;
        margin-bottom: 5px;
      }

      // .flex_item_1 {
      //   li {
      //     font-size: $mobile-font-size;
      //   }
      //   // align-self: center;
      // }

      .flex_item_2 {
        min-width: 0%;
      }
    }
  }
}
