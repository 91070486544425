// embedded content

.embedded_container_hidden {
  display: none;
}

.embedded_container {
  main {
    background-color: black;
    background-color: #1e1e1e;

    color: $red-background;
    color: white;
  }

  section {
    margin-bottom: 20px;
  }

  .embedded_txt {
    margin-top: 20px;
    margin-right: 27px;
    p {
      width: 100%;
    }
  }

  h2 {
    margin-top: 20px;
    border-bottom: 1px dotted $red-background;
    color: $red-background;
    margin: 0 30px 5px 0;
  }

  h2:nth-of-type(1) {
    margin-bottom: -10px;
  }

  @media screen and (max-width: 430px) {
    h2 {
      font-size: 28px;
    }
  }

  .audio_flex_container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
    flex-wrap: wrap;
    margin-left: -20px;

    @media screen and (max-width: 764px) {
      .spectro_1 {
        border-bottom: 1px dotted $red-background;
        margin: 0 10px 0 10px;
        summary {
          margin-bottom: 48px;
        }
      }

      .spectro_1:nth-of-type(2) {
        margin-top: 20px;
      }

      .spectro_1:nth-last-of-type(1) {
        margin-top: 20px;
        margin-bottom: 0px;
        border-bottom: 0;
        summary {
          margin-bottom: 0px;
        }
      }
    }

    h3 {
      margin-top: 15px;
      text-align: center;
      margin-bottom: 15px;
    }
    audio {
      border: $red-background solid 12px;
      border-radius: 50px;
      padding: 10px;
      width: 240px;
      // width: 90%;
      margin: 10px;
    }

    // audio summary

    details {
      summary {
        margin: 15px 0px 20px 77px;
        text-align: center;
        background-color: $red-background;
        padding: 5px 0;
        border-radius: 40px;
        max-width: 150px;
      }
      ul {
        margin-top: 30px;
        list-style: square;
        max-width: 200px;
        background-color: $red-background;
        border-radius: 11px;
        list-style: none;
        margin-left: 30px;
        margin-bottom: 40px;
        padding: 20px 20px 5px 20px;
        li {
          margin-bottom: 20px;
          max-width: 270px;
        }
      }
    }

    @media screen and (max-width: 390px) {
      audio {
        width: 200px;
      }

      details {
        summary {
          margin-left: 56px;
        }
      }
    }
  }

  .video_flex_container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-left: -20px;
    // width: 80%;

    h3 {
      margin-top: 15px;
      text-align: center;
      margin-bottom: 15px;
    }

    video {
      margin-top: 30px;
      padding: 15px;
      // border: $red-background solid 6px;
      width: 80%;
      border-radius: 10px;
      height: auto;
    }
  }

  .photography_container {
    background-color: #1e1e1e;
    ul {
      margin: 30px 0px 10px -20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: space-around;
      li {
        img {
          width: 300px;
          height: auto;
          padding: 15px;
          border-radius: 5px;
          margin: 10px 10px;
        }
      }
    }

    @media screen and (max-width: 841px) {
      ul {
        margin-left: -20px;

        li img {
          width: 80%;
          height: auto;
          margin-left: 8%;
          margin-right: 8%;

          margin: 0% 8% 0% 8%;
        }
      }
    }

    @media screen and (max-width: 660px) {
      h2:nth-of-type(1) {
        margin-bottom: -20px;
      }
      ul {
        margin-left: -24px;
      }
    }
    // iPhone XR and below
    @media screen and (max-width: 414px) {
      ul {
        margin-left: -34px;
        margin-bottom: -12px;
      }
    }
  }
}

// go back and move all the content out to seperate files and just leave code logic
