.text_page_hidden {
  display: none;
}

.textPageContainer {
  main {
    display: flex;
    background-color: white;
    flex-wrap: wrap;
    justify-content: space-between;

    // flex item 1

    .textPage_container1 {
      width: 60%;
      min-width: 400px;
      line-height: 19px;

      h2 {
        border-bottom: 0;
      }

      .red_paragraph {
        color: $red-background;
      }

      p {
        border-bottom: 1px dotted $red-background;
        margin-left: 0px;
        padding-left: 0px;
      }

      P:nth-of-type(1) {
        border-top: 1px dotted $red-background;
      }

      i,
      em,
      b,
      strong,
      address,
      a,
      sup,
      sub,
      del,
      abbr,
      cite,
      q,
      blockquote,
      time,
      span,
      code,
      small,
      bdi,
      bdo {
        color: $red-background;
      }

      samp {
        color: $red-background;
      }
      mark {
        background-color: $red-background;
        padding: 0px 5px;

        color: white;
        border-radius: 3px;
      }

      kbd {
        background-color: $red-background;
        color: white;
        padding: 5px 5px 3px 5px;
        border: 0;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
          0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
        display: inline-block;
        font-size: 0.85em;
        font-weight: 700;
        line-height: 1;
        white-space: nowrap;
      }
    }

    // flex item 2

    .textPage_container2 {
      width: 33%;
      min-width: 282px;
      // border-left: 1px dotted $red-background;
      padding-left: 30px;
      margin-left: 20px;

      article {
        border-left: $red-background 1px dotted;
        width: 100%;
        margin: -10px 0 0 -30px;

        header {
          background-color: $red-background;
          border-right: 30px solid $red-background;
          border-top-right-radius: 20px;
          color: white;
          padding: 10px;
          width: 310px;
          width: 98%;
          display: block;
          text-align: left;

          h2 {
            text-align: left;
            border-bottom: 1px dotted white;
            margin: 0 0 2px 10px;
          }
          time {
            font-size: 13px;
            padding-left: 10px;
            text-align: left;
          }
        }

        p {
          font-size: 17px;
          line-height: 20px;
          padding: 20px 0 20px 40px;
        }

        p:nth-of-type(2) {
          padding: 10px 0 30px 40px;
        }
        hr {
          border-top: 1px dotted $red-background;
        }
      }

      article:nth-of-type(2) {
        header {
          border-top-right-radius: 0px;
        }
      }

      article:nth-of-type(3) {
        border-bottom: 1px dotted $red-background;
        header {
          border-top-right-radius: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 982px) {
    main {
      justify-content: flex-start;
      .textPage_container1 {
        width: 100%;
        min-width: 0px;

        p {
          margin-right: 20px;
        }
        p:nth-last-of-type(1) {
          border-bottom: 0;
          margin-bottom: -30px;
        }
      }

      .textPage_container2 {
        width: 100%;
        padding-left: 0;
        min-width: 0px;

        article {
          header {
            border-top-right-radius: 0;
            margin-top: 40px;
          }

          p {
            padding-bottom: 10px;
          }
          // p:nth-of-type(2) {
          //   padding-bottom: 10px;
          // }
          p:nth-of-type(2) {
            padding: 10px 0 0px 40px;
          }

          hr {
            display: none;
          }
        }

        article:nth-of-type(3) {
          // border-bottom: 0;
          p:nth-of-type(2) {
            padding-bottom: 30px;
            // border-bottom: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 430px) {
    main {
      h2:nth-of-type(1) {
        font-size: 28px;
        // color: green;
      }

      .textPage_container2 {
        article {
          header {
            width: 100%;
            padding-right: 0px;
          }
          p {
            // font-size: 12px;
            padding-bottom: 20px;
          }
          p:nth-of-type(3) {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

// need to figure out best way to use SASS with media queries when stucture and specificity starts making a lot of extra coding?
// is importing / splitting out code the best way to deal with this?
