.datalist_container {
  background-color: white;
  border: 2px solid $light-grey;
  border-radius: 5px;
  width: 330px;

  p {
    margin-bottom: 30px;
    // margin-top: 10px;
    margin-left: 0;
    background-color: white;
    padding-left: 10px;
    color: $dark-grey;
  }

  label {
    background-color: $red-background;
    // padding: 10px;
  }
  input {
    background-color: $red-background;
  }
  datalist {
    background-color: yellow;
    option {
      background-color: $red-background;
      color: orange;
    }
  }
}
