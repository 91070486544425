// table container

.tag_page_hidden {
  display: none;
}

.tag_page_container {
  h2 {
    text-align: center;
    margin-right: 10px;
    span {
      color: $red-background;
    }
  }

  .tag_table_container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-top: 20px;
    // margin-bottom: 15px;
    margin-left: -18px;

    table {
      width: 94%;
      position: relative;
      border-collapse: collapse;
      background-color: white;
      border: solid 1px #ededed;
      border-radius: 10px;
      margin-bottom: 5px;
      font-size: 20px;
    }

    caption {
      text-align: center;
      margin: 20px 0 10px;
      font-weight: bold;
      font-size: 25px;
    }

    // Table Header
    thead {
      background-color: #f7f7f5;
    }

    th {
      background-color: white;
      position: sticky;
      border-bottom: solid 1px #ededed;
      padding: 30px 0px 10px 0px;
      align-self: flex-start;
    }

    th:nth-child(1) {
      width: 15%;
      text-align: left;
      padding-left: 25px;
      border-right: solid 1px #ededed;
      border-top-left-radius: 30px;
    }

    th:nth-child(2) {
      width: 85%;
      text-align: left;
      padding-left: 20px;
      border-right: solid 1px #ededed;
      border-top-right-radius: 30px;
    }

    // ************************* TABLE DATA *************************

    td {
      padding: 13px 0px 13px 0px;
      border-bottom: solid 1px #ededed;
    }

    // table data - tag column
    td:nth-of-type(1) {
      text-align: right;
      text-align: left;
      padding: 12px 15px 12px 25px;
      border-right: solid 1px #ededed;
    }

    // table data - description column
    td:nth-of-type(2) {
      text-align: left;
      padding: 10px 15px 10px 25px;
    }

    tr:nth-last-of-type(1) {
      td:nth-of-type(1) {
        // background-color: green;
        border-bottom-left-radius: 10px;
      }
      td:nth-of-type(2) {
        // background-color: yellow;
        border-bottom-right-radius: 10px;
      }

      // background-color: green;
    }

    @media screen and (max-width: 414px) {
      table {
        font-size: 17px;
      }

      td:nth-of-type(1) {
        padding: 10px 10px 12px 12px;
        font-weight: bold;
      }
      td:nth-of-type(2) {
        padding: 10px 10px 12px 12px;
      }

      th {
        padding: 20px 0px 10px 0px;
      }

      th:nth-child(1) {
        padding-left: 12px;
      }

      th:nth-child(2) {
        padding-left: 12px;
      }
    }
  }

  // this stuff needs moving --->
  .red {
    background-color: $red-background;
    color: white;
  }
  .white {
    background-color: white;
  }

  @media screen and (max-width: 414px) {
    h2 {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 320px) {
    h2 {
      font-size: 25px;
    }
  }
}
