// table

.table_page {
  .football_table_container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: -17px;

    .football_flex_item {
      width: 40%;
      min-width: 350px;
      max-width: 430px;
      margin-left: 20px;
      margin-right: 20px;
      // flex-basis: 25%;

      table {
        background-color: white;
        table-layout: fixed;
        border: solid 1px #ededed;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-left: -20px;
      }

      caption {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 25px;
      }

      colgroup {
        .team_position {
          background-color: white;
          color: white;
        }
        .points {
          background-color: rgb(123, 123, 228);
          background-color: $light-grey;
        }
      }

      // table header
      thead {
        background-color: #f7f7f5;
      }

      // Position Header
      thead th:nth-child(1) {
        width: 5%;
      }

      // Team Header
      thead th:nth-child(2) {
        width: 30%;
        text-align: left;
      }

      // LOGIC - if caption / table == Championship

      // P, GD and Pts Header

      thead th:nth-child(3),
      thead th:nth-child(4),
      thead th:nth-child(5) {
        width: 10%;
        text-align: center;
      }

      // Overall Padding on Table Header Row
      th {
        padding: 30px 0px 10px 0px;
      }

      // Padding on "Team" to put in line with Teams, padded off the position which is right aligned.
      th:nth-of-type(2) {
        padding-left: 20px;
      }

      // table row border formatting
      tr {
        border-bottom: solid 1px #ededed;
      }

      .my_teams {
        background-color: $red-background;
        color: white;
      }

      // NEED TO REVISIT THIS CODE, MUST BE A BETTER WAY ------->

      .pl_formatting {
        tr:nth-of-type(4),
        tr:nth-of-type(6),
        tr:nth-of-type(7),
        tr:nth-of-type(17) {
          border-bottom: dashed 1px black;
        }
      }
      // championship format
      .ch_formatting {
        tr:nth-of-type(2),
        tr:nth-of-type(6),
        tr:nth-of-type(21) {
          border-bottom: dashed 1px black;
        }
      }
      // league one format
      .l1_formatting {
        tr:nth-of-type(2),
        tr:nth-of-type(6),
        tr:nth-of-type(20) {
          border-bottom: dashed 1px black;
        }
      }
      // league two format
      .l2_formatting {
        tr:nth-of-type(3),
        tr:nth-of-type(7),
        tr:nth-of-type(22) {
          border-bottom: dashed 1px black;
        }
      }

      // League One - extra border below line 2, 6, 20.
      // League Two - extra border below line 3, 7, 22.

      // table data
      td {
        padding: 13px 0px 13px 0px;
        margin-right: 300px;
        text-align: center;
      }

      td:nth-of-type(1) {
        text-align: right;
      }
      td:nth-of-type(2) {
        text-align: left;
        font-weight: bold;
        padding-left: 20px;
      }
      td:nth-of-type(5) {
        font-weight: bold;
      }

      .leaguePosition {
        // background-color: blue;
        text-align: right;
      }

      // Bring the same as a separate scss file

      // the empty line
      .gap_line {
        background-color: #ededed;
        color: #ededed;
      }

      .next_steps_table {
        background-color: blue;
      }

      .football_tables_info {
        color: white;
        // margin-left: 5%;
        // margin-right: 5%;
        // margin-left: 30px;
        margin-right: 2px;
        margin-left: -15px;
        margin-top: -1px;
        background-color: $red-background;
        border: 1px solid $red-background;
        border-radius: 20px;
        padding: 5px 8px;

        p {
          padding: 0;
          padding-bottom: 8px;
          margin-left: 0;
          text-align: center;
        }

        p:nth-of-type(1) {
          padding-top: 10px;
        }

        p:nth-of-type(3) {
          padding-bottom: 10px;
        }
      }
    }
  }
  // Next steps section
  ol {
    margin-left: 20px;
    li {
      list-style: square;
      padding: 10px 0;
      // background-color: yellow;
    }
  }
  @media screen and (max-width: 948px) {
    .football_table_container {
      margin-bottom: -17px;
      .football_flex_item {
        caption {
          margin-bottom: 10px;
        }

        .football_tables_info {
          margin-bottom: 30px;
        }
      }
    }
  }
  @media screen and (max-width: 560px) {
    .football_table_container .football_flex_item {
      min-width: 320px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 430px) {
    h2 {
      font-size: 28px;
    }

    .football_table_container .football_flex_item caption {
      font-size: 20px;
    }

    .football_table_container .football_flex_item {
      min-width: 270px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 370px) {
    .football_table_container .football_flex_item {
      min-width: 250px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 360px) {
    .football_table_container .football_flex_item {
      min-width: 240px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 330px) {
    .football_table_container .football_flex_item {
      min-width: 220px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 300px) {
    .football_table_container .football_flex_item {
      min-width: 190px;
      padding: 5px 0px 5px 0px;
    }

    td,
    tr {
      font-size: 10px;
    }
  }
}

// still need to figure out the issue with spacing the tables out, I'm sure it's something to do with width % vs flexbox causing some weirdness
