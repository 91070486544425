// sass variables

// $red-background: rgb(206, 92, 92);
$red-background: #b95561;

$light-red-background: rgb(208, 155, 155);
$light-red-background: #c14242;
$light-red-background: #d37883;

$light-grey-background: #ededed;
$text-colour: white;
$black-font: rgb(34, 5, 5);
$light-grey: #dddddd;
$dark-grey: #757575;
// $cool-black: #1e1e1e;
$cool-black: #1f1f20;

$blue: #3d8cad;
$joe_black: #1f1f20;
$background_grey: #efece4;

$new-red-background: red;

$mobile-font-size: 12px;
