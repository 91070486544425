.header_container {
  // desktop view

  header {
    display: flex;
    background-color: $red-background;
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: space-between;

    .title_and_logo_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 70px;

      .Link {
        text-decoration: none;
      }

      img {
        height: auto;
        animation: rotation 3s linear;
        color: white;
        height: auto;
        width: 50px;
        margin-top: 4px;
      }

      h1 {
        font-size: 45px;
        margin-left: 6px;
        color: $black-font;
        font-family: "Helvetica Neue", Arial, sans-serif;
        text-decoration: none;
      }
    }

    nav {
      font-family: "Helvetica Neue", Arial, sans-serif;

      ul {
        display: flex;
        flex-direction: row;
        list-style: none;

        grid-gap: 5px;
        margin-right: 80px;

        a {
          color: $black-font;
          color: white;
          padding: 0.5rem;
          text-decoration: none;

          li {
            font-weight: bold;
            font-size: 17px;
            font-family: "Montserrat", sans-serif;
            list-style-type: none;
            width: 100%;
            text-align: center;
          }
        }
        a:hover {
          background-color: $light-red-background;
          border-radius: 4px;
          transition: all 0.2s ease-out;
          opacity: 1;
        }
      }
    }
  }

  .menu-icon {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    header {
      .title_and_logo_container {
        margin-left: 40px;
        h1 {
          margin-left: 8px;
        }
      }
      nav {
        ul {
          margin-right: 60px;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    header {
      .title_and_logo_container {
        // margin: 0;
        margin-left: 50px;

        // h1 {
        //   margin-left: 8px;
        // }
      }

      // responsive nav menu
      .nav-menu.active {
        background: $red-background;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        display: visible;
      }

      // .nav-menu.active {
      //   display: none;
      // }

      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 90px;
        left: -100%;
        opacity: 1;
        transition: all 0.5 ease;
        display: visible;
        height: 100%;
        padding: 0;
      }

      ul {
        overflow-y: hidden !important;
        height: 100%;
        a {
          li {
            text-align: center;
            font-size: 25px !important;
            padding: 1.5rem 0;
            display: table;
          }

          li:hover {
            border-radius: 0;
          }
        }
      }

      .menu-icon {
        display: block;
        // display: visible;
        position: absolute;
        transform: translate(-100%, 60%);
        /////////
        font-size: 2rem;
        top: 8px;
        right: 40px;
        font-size: 2rem;
        cursor: pointer;
      }
    }

    justify-content: center;
  }

  @media screen and (max-width: 670px) {
    header {
      justify-content: flex-start;

      .menu-icon {
        top: 14px;
        right: 33px;
        font-size: 1.8rem;
      }
      .title_and_logo_container {
        margin-left: 60px;

        h1 {
          // margin-left: -10px;
          font-size: 40px;
          margin-left: 6px;
        }

        img {
          width: 45px;
          // display: none;
        }
      }
    }
  }

  // Surface Duo (540px)
  @media screen and (max-width: 540px) {
    header {
      .menu-icon {
        font-size: 1.6rem;
        top: 18px;
        right: 21px;
      }
      .title_and_logo_container {
        h1 {
          font-size: 40px;
          margin-left: 10px;
        }
      }
      img {
        width: 50px;
        margin-top: 3px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    header {
      .menu-icon {
        right: 10px;
        top: 18.5px;
        font-size: 1.5rem;
      }
      .title_and_logo_container {
        font-size: 30px;
        margin-left: 25px; // margin-left: 0px;
        h1 {
          font-size: 33px;
          // margin-left: 37px;
        }
        img {
          width: 40px;
          margin-top: 6px;
          margin-left: 7px;
        }
      }
    }
  }

  // iPhone XR (414px), Galaxy S20/A51/A71 (412px), Pixel (411px)
  @media screen and (max-width: 430px) {
    header {
      height: 70px;

      .nav-menu {
        top: 70px;
      }

      .menu-icon {
        right: 16px;
        top: 9px;
      }
      .title_and_logo_container {
        margin-left: 25px;
        h1 {
          margin-left: 10px;
        }
        img {
          width: 40px;
          margin-top: 7px;
          margin-left: 5px;
        }
      }
    }
  }

  // Pixel 5 (393px), iPhone 12 Pro (390px)
  @media screen and (max-width: 404px) {
    header {
      .menu-icon {
        font-size: 1.3rem;
        top: 13px;
      }
      .title_and_logo_container {
        font-size: 30px;

        img {
          width: 36px;
          margin-top: 5px;
        }

        h1 {
          font-size: 27px;
          margin-left: 9px;
        }
      }
    }
  }

  // Pixel 5 (393px), iPhone 12 Pro (390px)
  // iPhone X, iPhone SE (375px)
  // Moto G4, Galaxy S5 / S8 (360px)

  @media screen and (max-width: 393px) {
    header {
      .menu-icon {
        font-size: 1.3rem;
        top: 14px;
      }
      .title_and_logo_container {
        font-size: 30px;

        img {
          width: 36px;
          margin-top: 5px;
        }

        h1 {
          font-size: 27px;
          margin-left: 9px;
        }
      }
    }
  }

  // iPhone 5/6/7/8 (320px)

  @media screen and (max-width: 350px) {
    header {
      .menu-icon {
        font-size: 1.2rem;
        top: 12.5px;
        right: 12px;
      }
      .title_and_logo_container {
        margin-left: 20px;

        img {
          width: 33px;
          margin-top: 8px;
        }

        h1 {
          font-size: 25px;
          // margin-left: 10px;
        }
      }
    }
  }
}
