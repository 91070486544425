::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark-grey;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $dark-grey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $dark-grey;
}
